import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { SassyText, Section, SectionWrapper, Text, Title } from "../../core/commonExports"
import { Button } from "../../core/Button"
import ReviewStar from "../../../assets/images/review-star.svg"
import { OnDesktop, OnMobile } from "../../DesktopMobileComps"
import { noOfBusinesses } from "../../../utils/variables"

const ReviewSection = ({handleGetQuoteClick, lpName="GLP2", sectionBg="#fffbf9", hideQuoteCTA = false}) => {
    return (
        <Section background={sectionBg}>
          <SectionWrapper>
            <Title fontSize="36px" mfontSize="24px" fontWeight="bold" color="#333333" style={{textAlign: "center", marginBottom: "64px"}}>
                {lpName === "GLP2" 
                    ? `Join ${noOfBusinesses} business owners who trust us`
                    : <SassyText>Join {noOfBusinesses} business owners who rely on us</SassyText>
                }
            </Title>
            <ReviewContainer>
                <TestimonialImgContainer>
                    <StaticImage 
                        src="../../../assets/images/testimonial_anuj_g.webp"
                        alt="testimonial_anuj"
                        loading="lazy"
                    />
                </TestimonialImgContainer>
                <ReviewCard>
                    <ReviewDataContainer>
                        <ReviewData>
                            <ReviewerImgContainer>
                                <StaticImage 
                                    src="../../../assets/images/anuj-gupta.webp"
                                    alt="anuj-gupta"
                                    loading="lazy"
                                />
                            </ReviewerImgContainer>
                            <div style={{width: "100%"}}>
                                <Text fontSize="20px" mfontSize="18px" fontWeight="bold" style={{textAlign: "left"}}>Anuj Gupta</Text>
                                <Text fontSize="16px" mfontSize="12px" color="#333333" style={{textAlign: "left"}} desktopStyles={{margin: "20px 0"}} mobileStyles={{margin: "12px 0"}}>
                                    Automobile parts trader in Faridabad
                                </Text>
                                <div style={{display: "flex", margin: "16px 0"}}>
                                    <ReviewStar />
                                    <ReviewStar />
                                    <ReviewStar />
                                    <ReviewStar />
                                    <ReviewStar />
                                </div>
                            </div>
                        </ReviewData>
                        <GoogleLogoContainer>
                            <StaticImage 
                                src="../../../assets/images/campaigns/review-imgs/google.webp"
                                alt="google-review"
                                loading="lazy"
                                placeholder="blurred"
                            />
                        </GoogleLogoContainer>
                    </ReviewDataContainer>
                    <ReviewText>
                        I was looking to insure my business for a <strong>long time</strong> but was <strong>confused</strong> about the procedure and coverage. Then this <strong>Verak team approached</strong> me. Even though the entire purchase was done online, I found the <strong>process very transparent and the staff very supportive.</strong> I ended up buying it at an <strong>affordable</strong> price and that too with extra benefits. It was totally worth it!
                    </ReviewText>
                </ReviewCard>
            </ReviewContainer>
            {!hideQuoteCTA && (
                <>
                    <OnDesktop><Button className="on-desktop" wide label="GET A QUOTE" style={{margin: "auto", marginTop: "98px"}} onClick={handleGetQuoteClick} /></OnDesktop>
                    <OnMobile><Button className="on-mobile" label="GET A QUOTE" style={{margin: "auto", marginTop: "36px", width: "150px"}} onClick={handleGetQuoteClick} /></OnMobile>
                </>
            )}
          </SectionWrapper>
        </Section>
    )
}

const ReviewContainer = styled.article`
    display: flex; 
    justify-content: center; 
    gap: 56px;
    @media(max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 36px;
    }
`

const TestimonialImgContainer = styled.div`
    @media (max-width: 768px) {
        padding: 0 1rem;
    }
`

const ReviewCard = styled.div`
    width: min(600px, 100%);
    @media (max-width: 768px) {
        padding: 0 1rem;
    }
`

const ReviewerImgContainer = styled.div`
    width: 96px;
    @media (max-width: 768px) {
        width: 59px;
    }
`

const ReviewData = styled.div`
    display: flex; 
    gap: 56px; 
    width: 600px;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }
`

const ReviewDataContainer = styled.div`
    display: flex;
    gap: 56px;
    @media (max-width: 768px) {
        gap: 24px;
        margin-right: 24px;
    }
`

const GoogleLogoContainer = styled.div`
    width: 36px;
    margin-top: 8px;
    @media (max-width: 768px) {
        width: 24px;
        margin-top: 24px;
    }
`

const ReviewText = styled(Text)`
    margin: 0;
    margin-left: 130px;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    @media screen and (max-width: 768px){
        margin-left: 0px;
        font-size: 14px;
    }
`

export default ReviewSection